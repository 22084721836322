import * as React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactHero from "../components/heros/contactHero"

const ContactText = styled.div`
text-align: center;
margin-top: 64px;
margin-bottom: 64px;
`

const Contact = () => (
	<Layout>
		<Seo title="Page two" />
		<ContactHero />
		<ContactText>
			<p><strong>Email us at:</strong><br />
				info@relentlessfiresafety.ca<br />
				sales@relentlessfiresafety.ca</p>
			{/* <p>Phone: 250.462.9111</p> */}
			<p><strong>Hours:</strong><br />Mon-Fri 9am - 5pm</p>
			<p>Unit 2 - 376 Main Street<br />
				Penticton, BC V2A 5C3</p>
		</ContactText>
	</Layout>
)

export default Contact